.addNewTopic__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--basic-color);
}

.addNewTopic__inputElement-wrapper {
  display: flex;
  flex-direction: column;
}

.addNewTopic__inputElement-wrapper input,
.addNewTopic__inputElement-wrapper textarea {
  background: var(--basic-background);
  color: var(--basic-color);
}

.addNewTopic__inputElement-wrapper input::placeholder,
.addNewTopic__inputElement-wrapper textarea::placeholder {
  color: var(--basic-color-dark);
}

.addNewTopic__inputElement-wrapper label {
  margin: 24px 0 8px 0;
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 500; /* TODO: check with PP */
  font-size: 12px;
  line-height: 20px;
  color: var(--basic-color);
}

.addNewTopic__keywords-input-wrapper {
  min-height: 40px;
  width: 100%;
  padding: 0;
  border: 1px solid var(--basic-background-light);
  box-sizing: border-box;
  border-radius: 8px;
}

.addNewTopic__keywords-input-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.addNewTopic__keywords-input-wrapper:focus-within {
  border: 1px solid #FA541C;
  box-shadow: 0 0 0 2px rgba(250, 84, 28, 0.12);
}

.addNewTopic__keywords-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.addNewTopic__keywords-item {
  position: relative;
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--basic-color);
  padding: 0 25px 0 8px;
  list-style: none;
  border-radius: 100px;
  margin: 0 8px 8px 0;
  background: var(--basic-background-light);
}

.addNewTopic__keywords-item-keyword {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.addNewTopic__keywords-item-close>svg {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.addNewTopic__keywords-item-close>svg>path {
  fill: var(--basic-color);
}

.addNewTopic__keywords-saved-keywords-wrapper {
  position: relative;
  width: 100%;
}

.addNewTopic__saved-keywords {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: var(--basic-background-light);
  color: var(--basic-color);
  max-height: 270px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #F5F5F5 var(--basic-white);
}

.addNewTopic__saved-keywords::-webkit-scrollbar {
  width: 6px;
}

.addNewTopic__saved-keywords::-webkit-scrollbar-track {
  background-color: var(--basic-white);
  border-radius: 100px;
}

.addNewTopic__saved-keywords::-webkit-scrollbar-thumb {
  background-color: #F5F5F5;
  border-radius: 100px;
}

.addNewTopic__saved-keyword {
  margin: 9px 0;
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
}

.addNewTopic__saved-keyword:first-child {
  margin-top: 18px;
}

.addNewTopic__saved-keyword:last-child {
  margin-bottom: 18px;
}

.addNewTopic__keywords-input {
  flex: 1;
  width: 100%;
  height: 40px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.addNewTopic__keywords-helperText {
  margin-top: 8px;
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: var(--basic-color-dark);
}

.addNewTopic__description-textarea {
  width: 100%;
  height: 120px;
  border: 1px solid var(--basic-background-light);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 9px;
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  resize: none;
  outline: none;
}

.addNewTopic__button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
