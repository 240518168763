.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 24px;
}

.pagination__left-arrow:disabled,
.pagination__right-arrow:disabled {
    opacity: .4;
    pointer-events: none;
}

.pagination__right-arrow {
    transform: rotateZ(180deg);
}

.pagination__left-arrow,
.pagination__right-arrow,
.pagination__page,
.pagination__pages span {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    background: var(--basic-background);
    color: var(--basic-color);
    padding: 15px;
}

.pagination__page,
.pagination__pages span {
    margin: 0 5px;
}

.pagination__pages {
    display: flex;
    align-items: center;
}

.pagination__pages span {
    transform: rotateZ(90deg);
}

.pagination svg>path {
    fill: var(--basic-color);
}

.pagination__left-arrow:hover,
.pagination__right-arrow:hover,
.pagination__page:hover,
.pagination__page-active {
    background: var(--basic-background-light);
}

.pagination-disabled {
    opacity: .4;
    pointer-events: none;
}