.add-new-view__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: var(--basic-color);
}

.add-new-view__text {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 8px;
  color: var(--basic-color);
}

.add-new-view__text + input {
  background-color: var(--basic-background);
  color: var(--basic-color);
}

.add-new-view__text + input::placeholder {
    color: var(--basic-color-dark);
}

.add-new-view__button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
}
