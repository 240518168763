.calendar__top-container {
  display: flex;
  padding: 16px;
  justify-content: space-between;
}

.calendar__prev-month-button,
.calendar__next-month-button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.calendar__next-month-button svg {
  transform: rotateZ(180deg);
}

.calendar__prev-month-button svg path,
.calendar__next-month-button svg path {
  fill: var(--basic-color);
}

.calendar__calendar-table-weekdays,
.calendar__calendar-table-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar__month-year {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--basic-color);
}

.calendar__calendar-table-weekday {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--basic-color-dark);
  background-color: var(--basic-background-light);
}

.calendar__calendar-table-weekday {
  box-sizing: border-box;
  border: solid 1px var(--basic-background-dark);
  height: 32px;
  text-align: right;
  padding-top: 6px;
  padding-right: 12px;
}

.calendar__calendar-table-day {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  border: solid 1px  var(--basic-background-dark);
  height: 70px;
  text-align: right;
  padding-top: 12px;
  padding-right: 12px;
  cursor: pointer;
  color: var(--basic-color);
}

.calendar__calendar-table-day:hover {
  background-color: var(--basic-background-dark);
}

.calendar__calendar-table-weekday:nth-child(7n),
.calendar__calendar-table-day:nth-child(7n) {
  border-right: none;
}

.calendar__calendar-table-weekday:nth-child(7n+1),
.calendar__calendar-table-day:nth-child(7n+1) {
  border-left: none;
}

.calendar__calendar-table-day--not-current-month {
  color: var(--basic-color-dark);
}

.calendar__calendar-table-day--last-row {
  border-bottom: none;
}
