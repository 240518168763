.panel__wrapper {
    display: grid;
    grid-template-columns: 288px auto;
    grid-template-rows: auto 10fr;
    grid-template-areas:
    "n c"
    "n c";
}

.panel__content {
    grid-area: c;
    background-color: var(--basic-background-dark);
}

.welcome-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--basic-color);
    height: 100vh;
    font-style: italic;
    color: #aa9c93;
    font-family: 'helvetica-now-text', sans-serif;
}
