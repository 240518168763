.summary {
  grid-area: s;
  overflow-y: auto;
  visibility: hidden;
}

.summary:hover {
  visibility: visible;
}

.summary__wrapper {
  padding: 24px;
  text-align: center;
  visibility: visible;
  background: var(--basic-background);
  border-left: 1px solid var(--basic-background-light);
  min-height: 100%;
}

.summary__title {
  text-align: left;
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 13px;
  color: var(--basic-color);
}

.summary__toggle-wrapper {
  border-radius: 4px;
  background-color: var(--basic-background-light);
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.summary__toggle-button {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: var(--basic-color-dark);
  min-height: 40px;
  min-width: 75px;
  border: 1px solid var(--basic-background-light);
  border-radius: 4px;
  background-color: var(--basic-background-light);
  position: relative;
  cursor: pointer;
}

.summary__toggle-button:hover {
  color: var(--basic-color);
}

.summary__toggle-button:focus, .summary__toggle-button--active {
  background: var(--basic-background);
  color: var(--basic-color);
}

.summary__doughnut {
  width: 192px;
  height: 192px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  position: relative;
}

.summary__sources{
  margin-bottom: 24px;
}

.sources__header {
  text-align: left;
  display: flex;
  margin-bottom: 16px;
}

.sources__header-item {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--basic-color-dark);
}

.sources__header-item--source {
  width: 137px;
}
.sources__header-item--posts {
  width: 56px;
}

.sources__header-item--value {
  text-align: right;
}

.sources__source-item {
  display: flex;
  text-align: left;
  border-bottom: 1px dashed var(--basic-background-light);
  min-height: 40px;
  align-items: center;
}

.sources__source-name-wrapper {
  width: 137px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sources__indicator {
  width: 2px;
  height: 26px;
}

.sources__indicator--twitter {
  background: #1DA1F2;
}

.sources__indicator--facebook {
  background: #4267B2;
}

.sources__indicator--news {
  background: #FFA600;
}

.sources__indicator--instagram {
  background: #ec4868;
}

.sources__source-name {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  color: var(--basic-color);
  padding-left: 12px;
  padding-right: 8px;
  width: fit-content;
}

.sources__is-on-indicator {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.sources__is-off-indicator {
  width: 6px;
  height: 6px;
  background: #F5222D;
  border-radius: 50%;
}

.sources__source-posts {
  width: 56px;
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  color: var(--basic-color);
  padding-left: 5px;
}

.sources__source-value {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  color: var(--basic-color);
}

.keywords__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 24px;
  color: var(--basic-color);
}

.keywords__text {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--basic-color-dark);
}

.keywords__text--bordered {
  padding-bottom: 24px;
  border-bottom: 1px dashed  var(--basic-background-light);
}

.doughnut__body {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 0;
}

.doughnut__chart {
  position: relative;
  z-index: 1;
}

.doughnut__text {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color:  var(--basic-color-dark);
}

.doughnut__count {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color:  var(--basic-color);
}

.summary__toggle-disabled {
  opacity: .7;
  pointer-events: none;
}
