.content__wrapper {
  grid-area: c;
  background-color: var(--basic-background-dark);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content__posts {
  overflow-y: auto;
  visibility: hidden;
  padding: 6px 10px 6px 14px;
  margin-bottom: auto;
}

.content__posts:hover {
  visibility: visible;
}

.content__wrapper--grid .content__posts {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--basic-color);
  height: 100%;
  width: 100%;
  font-style: italic;
  color: #aa9c93;
  font-family: 'helvetica-now-text', sans-serif;
  visibility: visible;
}

.content__loader {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  visibility: visible;
}

.content__loader svg {
  height: 100px;
  width: 100px;
}

.content__posts-disabled {
  opacity: .4;
  pointer-events: none;
}