.modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-block {
    display: flex;
    flex-direction: column;
    background-color: var(--basic-background);
    max-width: 560px;
    max-height: 85vh;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
}

.modal-content {
    overflow-x: hidden;
    overflow-y: auto;
    margin: 32px 0;
    padding: 0 32px;
    z-index: 1;
    color: var(--basic-color);
}

.modal-content * {
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-size: 14px;
}

.modal-background {
    position: absolute;
    background-color: var(--basic-black-modalWrapper-background);
    width: 100%;
    height: 100%;
}

.modal-close-btn {
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
}

.modal-close-btn>svg {
    width: 12px;
    height: 12px;
}

.modal-close-btn>svg>path {
    fill: var(--basic-color-dark);
}
