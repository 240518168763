.add-new-label__title {
    font-family: 'helvetica-now-text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    color: var(--basic-color);
  }
  
  .add-new-label__label,
  .add-new-label__subtitle {
    margin-top: 24px;
    font-family: 'helvetica-now-text', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--basic-color);
  }
  
  .add-new-label__label + input,
  .add-new-label__description-textarea {
    background: transparent;
    color: var(--basic-color);
  }
  
  .add-new-label__label + input::placeholder,
  .add-new-label__description-textarea::placeholder {
    color: var(--basic-color-dark);
  }
  
  .add-new-label__toggle-labels-container {
    width: 100%;
    height: 40px;
    background: var(--basic-background-light);
    border-radius: 4px;
    margin-bottom: 24px;
  }
  
  .add-new-label__toggle-button {
    font-family: 'helvetica-now-text', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: center;
    color: var(--basic-color);
    min-height: 40px;
    min-width: 50%;
    border: 1px solid var(--basic-background-light);
    border-radius: 4px;
    background-color: var(--basic-background-light);
    position: relative;
    cursor: pointer;
  }
  
  .add-new-label__toggle-button {
    color: var(--basic-color-dark);
  }
  
  .add-new-label__toggle-button:hover {
    color: var(--basic-color);
  }
  
  .add-new-label__toggle-button--active,
  .add-new-label__toggle-button--active:hover {
    background: var(--basic-background);
    color: var(--basic-color);
  }
  
  .add-new-label__description-textarea {
    width: 100%;
    height: 120px;
    border: 1px solid var(--basic-background-light);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 9px;
    font-family: 'helvetica-now-text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    resize: none;
    outline: none;
  }
  
  .add-new-label__button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
  