.set-new-password__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 560px;
    height: 100vh;
    margin: 0 auto;
}

.set-new-password {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    height: 450px;
    border-radius: 8px;
    background: var(--basic-white);
}

.set-new-password__title {
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

.set-new-password__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}