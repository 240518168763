.add-label-fullscreen__header {
  background: var(--basic-background);
  border-bottom: 1px solid var(--basic-background-light);
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-label-fullscreen__header-post {
  display: flex;
  flex-direction: row;
}

.add-label-fullscreen__image-wrapper {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.add-label-fullscreen__header-highlight {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  width: 477px;
  height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--basic-color);
}

.add-label-fullscreen__header-source-link {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--basic-color-dark);
}

.add-label-fullscreen__header-toggle-wrapper {
  width: 100%;
  height: 40px;
  background: var(--basic-background-light);
  border-radius: 4px;
  max-width: 332px;
}

.add-label-fullscreen__header-search-container {
  position: relative;
}

.add-label-fullscreen__header-search-container input {
  background-color: var(--basic-background);
  color: var(--basic-color);
}

.add-label-fullscreen__header-search-container input::placeholder {
  color: var(--basic-color-dark);
}

.add-label-fullscreen__header-search-container:after {
  content: "";
  position: absolute;
  left: 14px;
  top: 13px;
  bottom: 0;
  width: 20px;
  background: url(magnifier-icon.svg) no-repeat 0 0;
}

.label-fullscreen-item__buttons-wrapper {
  display: flex;
  flex-direction: row;
}

.add-label-fullscreen__header .small-white-button {
  background: var(--basic-background);
  border-radius: 8px;
  border: 2px solid var(--basic-background-light);
  color: var(--basic-color);
}

.add-label-fullscreen__header .small-white-button:hover {
  background: var(--basic-background-light);
}
