.auth__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 560px;
    height: 100vh;
    margin: 0 auto;
}

.auth {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    height: 450px;
    border-radius: 8px;
    background: var(--basic-white);
}

.auth_title {
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

.auth__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth__toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 14px;
    font-family: Helvetica, sans-serif;
}

.auth__toggle-text {
    width: 170px;
}

.auth__icon {
    margin-right: 5px;
    align-self: center;
    padding-top: 5px;
}

