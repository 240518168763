.filter__bottom-container {
  border-top: 1px solid var(--basic-background-light);
}

.filter__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  color: var(--basic-color);
}

.filter__toggle-wrapper {
  width: 100%;
  height: 40px;
  background: var(--basic-background-light);
  border-radius: 4px;
  display: flex;
}

.filter__toggle-button {
  flex-grow: 1;
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: #8C8C8C;
  min-height: 40px;
  min-width: 165px;
  border: 1px solid var(--basic-background-light);
  border-radius: 4px;
  background-color: var(--basic-background-light);
  position: relative;
  cursor: pointer;
}

.filter__toggle-button:hover {
  background: inherit;
  color: var(--basic-color);
}

.filter__toggle-button--active,
.filter__toggle-button--active:hover {
  background: var(--basic-background);
  color: var(--basic-color);
}

.filter__social-network-block {
  display: flex;
}

.filter__social-network-wrapper {
  margin: 27px 0 5px 0;
}

.filter__social-network-checkbox {
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 2px;
  margin-right: 16px;
  margin-bottom: 22px;
  box-sizing: border-box;
  cursor: pointer;
}

.filter__checkbox-source {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--basic-color);
}

.social-checkbox-twitter-active {
  background: url(tick-icon.svg) no-repeat 50% 50% #1DA1F2;
}

.social-checkbox-twitter {
  background: none;
  border: 1px solid #1DA1F2;
}

.social-checkbox-facebook-active {
  background: url(tick-icon.svg) no-repeat 50% 50% #4267B2;
}

.social-checkbox-facebook {
  background: none;
  border: 1px solid #4267B2;
}

.social-checkbox-instagram-active {
  background:
    url(tick-icon.svg) no-repeat 50% 50% var(--special-instagram-magenta);
}

.social-checkbox-instagram {
  background: none;
  border:
    1px solid var(--special-instagram-magenta);
}

.social-checkbox-news-active {
  background: url(tick-icon.svg) no-repeat 50% 50% #FFA600;
}

.social-checkbox-news {
  background: none;
  border: 1px solid #FFA600;
}

.filter__labels-rule-container {
  display: flex;
  width: 100%;
  margin: 24px 0 24px 0;
  justify-content: flex-start;
  color: var(--basic-color);
}

.filter__labels-rule-title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-right: 32px;
}

.filter__labels-rule-block {
  display: flex;
  margin-right: 24px;
}

.filter__labels-rule-text {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.filter__labels-rule-button {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: solid 1px #DFDFDF;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 16px;
  margin-top: 2px;
}

.filter__labels-rule-button:hover {
  border: solid 2px #FA541C;
}

.filter__labels-rule-button--active {
  border: solid 2px #FA541C;
  background: url(red-radio-circle-icon.svg) no-repeat 50% 50% var(--basic-background);
}

.filter__labels-search-container {
  position: relative;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--basic-background-light);
}

.filter__labels-search-container input {
  background-color: var(--basic-background);
  color: var(--basic-color);
}

.filter__labels-search-container input::placeholder {
  color: var(--basic-color-dark);
}

.filter__labels-search-container:after {
  content: "";
  position: absolute;
  left: 14px;
  top: 13px;
  bottom: 0;
  width: 20px;
  background: url(magnifier-icon.svg) no-repeat 0 0;
}

.filter__labels-list-wrapper {
  padding-top: 21px;
  max-height: 275px;
  overflow: auto;
  visibility: hidden;
}

.filter__labels-list-wrapper:hover {
  visibility: visible;
}

.filter__labels-list-element-block {
  display: flex;
  color: var(--basic-color);
  visibility: visible;
}

.filter__labels-list-element-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  margin-right: 16px;
  margin-bottom: 22px;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: 1px solid #FA541C;
}

.filter__labels-first-element-checkbox--selected {
  background: url(tick-icon.svg) no-repeat 50% 50% #FA541C;
}

.filter__labels-first-element-text {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.filter__labels-posting-date-wrapper {
  padding-top: 24px;
  color: var(--basic-color);
}

.filter__labels-posting-date-wrapper input {
  background-color: var(--basic-background);
  color: var(--basic-color);
}

.filter__labels-posting-date-wrapper input::placeholder {
  color: var(--basic-color-dark);
}

.filter__labels-posting-date-container {
  margin-bottom: 24px;
  position: relative;
}

.filter__labels-posting-date-container input {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.filter__labels-posting-date-title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
}

.filter__button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
}

.filter__calendar-modal>.modal-block{
  max-width: 500px;
}
.filter__calendar-modal .modal-content {
  padding: 5px;
  margin-bottom: 0;
}