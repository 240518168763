.labels-header__title-topic {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}

.labels-header__control-panel {
  width: 530px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.labels-header__icon-wrapper {
  margin-right: 10px;
  display: flex;
  width: 18px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.labels-header__icon-wrapper svg {
  margin-right: auto;
  margin-left: auto;
}

.labels-header__icon-wrapper svg path {
  fill: var(--basic-color);
}

.labels-header__sort-by-date {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  padding: 9px 12px 9px 10px;
  min-width: 170px;
  height: 40px;
  background: transparent;
  border-radius: 4px;
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  margin-right: 16px;
  color: var(--basic-color);
}

.labels-header__sort-by-date--active {
  color: var(--basic-color);
  background: var(--basic-background-light);
}

.labels-header__sort-by-date:hover {
  background: var(--basic-background-light);
}

.labels-header__toggle-labels-container {
  width: 160px;
  height: 40px;
  background: var(--basic-background-light);
  border-radius: 4px;
  margin-right: 16px;
}

.labels-header__toggle-button {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: var(--basic-color-dark);
  min-height: 40px;
  min-width: 80px;
  border: 1px solid var(--basic-background-light);
  border-radius: 4px;
  background-color: var(--basic-background-light);
  position: relative;
  cursor: pointer;
}

.labels-header__toggle-button:hover {
  background: inherit;
  color: var(--basic-color);
}

.labels-header__toggle-button--active,
.labels-header__toggle-button--active:hover {
  background: var(--basic-background);
  color: var(--basic-color);
}


