.actions-wrapper {
  width: 140px;
  background: var(--basic-background);
  padding: 10px 0;
  border-radius: 4px;
  box-shadow:
    0 9px 28px 8px rgba(0, 0, 0, 0.05),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.actions-active {
  display: block;
}

.actions {
  display: flex;
  flex-direction: column;
}

.actions-button {
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 18px;
  color: var(--basic-color);
  display: flex;
  align-items: center;
}

.actions-button svg {
  margin-right: 10px;
  width: 14px;
}

.actions-button-delete {
  color: #F5222D;
}

.actions-button-delete:hover {
  background-color: rgb(245, 34, 45, .1);
}

.actions-button-edit:hover {
  background-color: rgb(255, 255, 255, .1);
}

.actions-button-agree,
.actions-button-select {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: #52C41A;
  cursor: pointer;
  position: relative;
}

.actions-button-agree:hover,
.actions-button-select:hover {
  background-color: rgba(82, 196, 26, 0.1);
}

.actions-button-disagree {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--basic-red);
  cursor: pointer;
  position: relative;
}

.actions-button-disagree:hover {
  background-color: rgba(245, 34, 45, 0.1);
}