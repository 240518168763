.label-fullscreen-item {
  background: var(--basic-background);
  border: 1px solid var(--basic-background-light);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin: 12px 24px;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
}

.label-fullscreen-item__name {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-right: 12px;
  color: var(--basic-color);
}
