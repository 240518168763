.labels-content__wrapper {
  grid-area: lc;
  background-color: var(--basic-background-dark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.label-list:hover {
  visibility: visible;
}

.label-list {
  visibility: hidden;
  overflow-y: auto;
}