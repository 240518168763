.avatar-image__wrapper {
  position: relative;
  display: inline-flex;
}

.avatar-image__image {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.avatar-image__image--social {
  border-radius: 50%;
}

.avatar-image__platform {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 26px;
  width: 26px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid var(--basic-white);
  transform: translate(40%, 40%);
}

.avatar-image__platform--facebook {
  background:
    url(facebook-icon.svg) no-repeat 50% 50% var(--special-facebook-blue);
}

.avatar-image__platform--twitter {
  background:
    url(twitter-icon.svg) no-repeat 50% 50% var(--special-twitter-blue);
}

.avatar-image__platform--instagram {
  background:
    url(instagram-icon.svg) no-repeat 50% 50%,
    var(--special-instagram-background);
}
