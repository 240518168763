.login__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 560px;
  height: 100vh;
  margin: 0 auto;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  height: 450px;
  border-radius: 8px;
  background: var(--basic-white);
}

.log_title {
  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.log__button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.login__recovery-link {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: var(--basic-black);
}

.login__recovery-link:visited {
  text-decoration: none;
  color: var(--basic-black);
}
