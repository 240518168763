.post__wrapper {
  background: var(--basic-background);
  border-radius: 4px;
  padding: 24px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  visibility: visible;
}

.post__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.post__avatar-source-wrapper {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.post__header-title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--basic-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  
  .post__header-title-wrapper {
    margin-right: auto;
    overflow: hidden;
  }
  
  .post__header-title::first-letter {
    text-transform: uppercase;
}

.post__header-title-description {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: var(--basic-color-dark);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post__header-date {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: var(--basic-color-dark);
}

.post__text {
  margin: 24px 0;
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: var(--basic-color);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post__labels-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.post__label-theme {
  background: var(--theme-background);
  border: 1px solid var(--theme-border-color);
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
}

.post__label-theme-title {
  margin-bottom: 2px;
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: var(--basic-color-dark);
}

.post__label-theme-text {
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--basic-color);
}

.post__label-opinion {
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 8px;
  margin-right: 8px;
  overflow: hidden;
}

.post__wrapper--grid {
  width: calc(50% - 12px);
}

.post__label-opinion--red {
  background: var(--opinion-disagree-background);
  border: 1px solid var(--opinion-disagree-border-color);
}

.post__label-opinion--green {
  background: var(--opinion-agree-background);
  border: 1px solid var(--opinion-agree-border-color);
}

.post__label-opinion-title {
  display: flex;
  margin-bottom: 2px;
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: var(--basic-color-dark);
}

.post__opinion-text {
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--basic-color);
}

.post__agree-text {
  margin-left: 13px;
  position: relative;
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: var(--basic-color-dark);
}

.post__agree-text:after {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  display: block;
  width: 1px;
  height: 12px;
  margin: 8px 0;
}

.post__agree-text--red:after {
  background: #FFCCC7;
}

.post__agree-text--green:after {
  background: #D9F7BE;
}
