.manage-views__top-container {
  display: flex;
  justify-content: space-between;
}

.manage-views__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  color: var(--basic-color);
}

.manage-views__views {
  margin-bottom: 32px;
}

.manage-views__view {
  margin-bottom: 8px;
  position: relative;
}

.manage-views__remove-view {
  position: absolute;
  top: 12px;
  right: 15px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.manage-views__view input {
  background-color: var(--basic-background);
  color: var(--basic-color);
}

.manage-views__view input::placeholder {
    color: var(--basic-color-dark);
}