.post-popup {
  position: relative;
  display: flex;
}

.post-popup__left-container {
  padding-right: 32px;
  flex-basis: 66%;
  max-width: 66%;
}

.post-popup__right-container {
  padding-left: 32px;
  border-left: 1px solid var(--basic-background-light);
  overflow: hidden;
}

.post-popup__header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 32px;
}

.post-popup__topic-source-avatar-wrapper {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.post-popup__labels-header {
  display: flex;
  justify-content: space-between;
}

.post-popup__topic-source-name {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--basic-color);
}

.post-popup__topic-source-link {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: var(--basic-color-dark);
}

.post-popup__add-label-link {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  color: var(--basic-color);
}

.post-popup__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 24px;
}

.post-popup__text {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 24px;
  color: var(--basic-color);
}

.post-popup__image-wrapper {
  width: 616px;
  margin-bottom: 24px;
}

.post-popup__data-name{
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: var(--basic-color-dark);
}

.post-popup__data-content {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 32px;
  color: var(--basic-color);
}

.post-popup__data-content--original-post-link {
  color: #1890FF;
  text-overflow: ellipsis;
  overflow: hidden;
}

.post-popup__data-link {
  text-decoration: none;
}

.post-popup__label-opinion-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.post-popup__label-theme {
  background: var(--theme-background);
  border: 1px solid var(--theme-border-color);
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 271px;
  position: relative;
  overflow: hidden;
}

.post-popup__label-theme-layer {
  position: absolute;
  height: 100%;
  max-width: 90px;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1001;
  background:
    linear-gradient(270deg, var(--basic-background-light) 33.33%, rgba(255, 241, 240, 0) 100%);
  display: none;
}

.post-popup__label-opinion-layer {
  position: absolute;
  height: 100%;
  max-width: 90px;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1001;
  display: none;
}

.post-popup__label-opinion-layer--agree {
  background:
    linear-gradient(270deg, var(--basic-background-light) 33.33%, rgba(255, 241, 240, 0) 100%);
}

.post-popup__label-opinion-layer--disagree {
  background:
    linear-gradient(270deg, var(--basic-background-light) 33.33%, rgba(255, 241, 240, 0) 100%);
}

.post-popup__label-theme-name,
.post-popup__opinion-title,
.post-popup__agree-text {
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
  color: var(--basic-color-dark);
}

.post-popup__label-theme-title,
.post-popup__opinion-text {
  font-family: 'inter-regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: var(--basic-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.post-popup__opinion {
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 271px;
  position: relative;
  overflow: hidden;
}

.post-popup__opinion--agree {
  background: var(--opinion-agree-background);
  border: 1px solid var(--opinion-agree-border-color);
}

.post-popup__opinion--disagree {
  background: var(--opinion-disagree-background);
  border: 1px solid var(--opinion-disagree-border-color);
}

.post-popup__opinion-title {
  margin-right: 13px;
}

.post-popup__agree-text {
  position: relative;
}

.post-popup__agree-text:after {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  display: block;
  width: 1px;
  height: 12px;
  margin: 8px 0;
}

.post-popup__agree-text--agree:after {
  background: #DDF8C4;
}

.post-popup__agree-text--disagree:after {
  background: #FFCCC7;
}

.post-popup__opinion-header {
  display: flex;
}

.post-popup__close {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 29px;
  right: 29px;
  cursor: pointer;
}

.post-popup__detach-label {
  position: absolute;
  top: 14px;
  right: 12px;
  cursor: pointer;
  display: none;
  z-index: 9999;
}

.post-popup__detach-label svg {
  height: 18px;
  width: 18px;
}

.post-popup__detach-label svg path {
  fill: var(--basic-color-dark);
}

.post-popup__label-theme:hover .post-popup__detach-label,
.post-popup__opinion:hover .post-popup__detach-label{
  display: block;
}

.post-popup__label-theme:hover .post-popup__label-theme-layer,
.post-popup__opinion:hover .post-popup__label-opinion-layer {
  display: block;
}

.post-popup__add-label-modal .modal-content,
.post-popup__add-label-fullscreen-modal .modal-content {
  margin: 0;
  padding: 0;
}

.post-popup__add-label-modal .modal-close-btn,
.post-popup__add-label-fullscreen-modal .modal-close-btn{
  display: none;
}

.post-popup__add-label-fullscreen-modal .modal-block {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
}