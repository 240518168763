.labels-content-dropdown__wrapper {
  padding: 9px 32px 9px 48px;
  background: var(--basic-background);
  border-bottom: 1px solid var(--basic-background-light);
  border-left: 1px solid var(--basic-background-light);
  display: flex;
  justify-content: space-between;
  color: var(--basic-color);
}

.labels-content-dropdown__selected-items-block {
  display: flex;
  align-items: center;
}

.labels-content-dropdown__selected-items-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: 1px solid var(--basic-red);
}

.labels-content-dropdown__selected-items-checkbox--active {
  background: url(minus-icon.svg) no-repeat 50% 50% var(--basic-red);
}

.labels-content-dropdown__selected-items-text {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.labels-content-dropdown__remove-block {
  position: relative;
  background-color: var(--basic-background);
  width: 96px;
  cursor: pointer;
  border: none;

  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--special-red-remove-button);
  text-align: right;
}

.labels-content-dropdown__remove-block::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 12px;
  width: 12px;
  height: 14px;
  background: url(trash-can-icon.svg) no-repeat 50% 50%;
}
