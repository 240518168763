.topic__item {
  display: flex;
  text-decoration: none;
  color: var(--basic-color-dark);
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0;
  height: 40px;
  padding-left: 10px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  visibility: visible;
}

.topic__item-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  padding-right: 5px;
}

.topic__item-content svg {
  flex-shrink: 0;
}

.topic__item--selected {
  background: var(--basic-background-light);
}

.topic__item:hover {
  color: var(--basic-color);
  background: var(--basic-background-light);
}

.topic__item-block:hover {
  background: linear-gradient(270deg, var(--basic-background-dark) 13.09%,
      rgba(245, 245, 245, 0) 50%);
}

.topic__item:hover .hashtag-icon {
  color: var(--basic-color);
}

.topic__item-text {
  width: 100%;
  margin-left: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 0;
}

.topic__item-block {
  width: 100%;
  height: 40px;
  display: none;
  border-radius: 4px;
}

.topic__item-block-active {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.topic__item-button-more {
  align-items: center;
  justify-content: center;
  width: 30px;
  flex-shrink: 0;
  display: none;
  background-color: var(--basic-background-dark);
  box-shadow: 0 0 30px 40px var(--basic-background-dark);
  z-index: 1;
}

.topic__item:hover .topic__item-button-more,
.topic__item-button-more.topic__item-button-more-active {
  display: flex;
}

.topic__item-button-more>svg>path {
  fill: var(--basic-color);
}

.topic__item--disabled {
  pointer-events: none;
  opacity: .6;
}
