.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;

  width: 100%;
  height: 40px;
  border: none;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;

  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  position: relative;
}

.auth__button {
  width: 235px;
}

.button--cancel {
  background: var(--basic-white);
  font-weight: 500;
}

/* .button--cancel:hover {
  background: #fafafafa;
} */

.button--reg {
  background: var(--basic-red);
  color: var(--basic-white);
  font-weight: 500;
}

.button--reg:hover {
  background: var(--special-red-button-hover);
}

.big-white-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;

  width: 260px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;

  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  position: relative;
  background: var(--basic-white);
  border: 2px solid var(--special-white-button-hover);
}

.big-white-button:hover {
  background: var(--special-white-button-hover);
}

.small-white-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  width: 90px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px;

  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  position: relative;
  background: var(--basic-background);
  color: var(--basic-color);
}

.small-white-button:hover {
  background: var(--basic-background-light);
}

.small-red-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  width: 90px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px;

  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  position: relative;
  background: #FA541C;
  color: var(--basic-white);
}

.small-red-button:hover {
  background: #E74246;
}

.small-green-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  width: 90px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px;

  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  position: relative;
  background: #52C41A;
  color: var(--basic-white);
}

.small-green-button:hover {
  background: #45A816FF;
}

.big-red-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;

  font-family: Helvetica, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;

  position: relative;
  background: #FA541C;
  color: var(--basic-white);
}

.big-red-button:hover {
  background: #E74246;
}
