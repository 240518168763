.settings__wrapper {
    display: grid;
    grid-template-columns: 288px auto;
    grid-template-rows: auto 10fr;
    grid-template-areas:
        "n c"
        "n c";
    background-color: var(--basic-background-dark);
}

.settings__container {
    grid-area: c;
    padding: 10px;
    font-family: 'helvetica-now-text', sans-serif;
    color: var(--basic-color);
}

.settings__content {
    background: var(--basic-background);
    padding: 15px;
    border-radius: 4px;
}

.settings__content h3 {
    padding-bottom: 10px;
}

.settings__content span {
    padding-right: 15px;
}

.settings__content select {
    background-color: transparent;
    color: var(--basic-color);
    border: 1px solid var(--basic-background-light);
    border-radius: 4px;
    padding: 10px;
    text-transform: capitalize;
}
