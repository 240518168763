.label-item__wrapper {
  margin: 12px 24px;
  background-color: var(--basic-background);
  border-radius: 4px;
  border: 1px solid var(--basic-background-light);
  padding: 24px 42px 24px 24px;
  position: relative;
  color: var(--basic-color);
  visibility: visible;
}

.label-item__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label-item__checkbox {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: 1px solid var(--basic-red);
  flex-shrink: 0;
}

.label-item__checkbox--active {
  background: url(tick-icon.svg) no-repeat 50% 50% var(--basic-red);
}

.label-item__name {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.labels-content-dropdown__more-button {
  position: absolute;
  top: 26px;
  right: 24px;
  border: none;
  cursor: pointer;
  background: transparent;
}

.labels-content-dropdown__more-button svg {
  width: 18px;
  height: 18px;
}

.labels-content-dropdown__more-button svg path {
  fill: var(--basic-color);
}

.more-menu__wrapper {
  display: none;
  width: 100px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 10;
  background: var(--basic-background);
  padding: 17px 16px;
  border-radius: 4px;
  box-shadow:
    0 9px 28px 8px rgba(0, 0, 0, 0.05),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.more-menu__wrapper--active {
  display: block;
}

.more-menu {
  display: flex;
  flex-direction: column;
}

.more-menu__button {
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  font-family: 'helvetica-now-text', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--basic-color);
}

.more-menu__button-delete {
  margin-top: 18px;
  color: var(--special-red-remove-button);
}
