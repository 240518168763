.navbar__wrapper {
  grid-area: n;
  background: var(--basic-background);
  border-right: 1px solid var(--basic-background-light);
}

.navbar__scroll {
  visibility: hidden;
  height: 100vh;
  width: 288px;
  overflow-y: auto;
}

.navbar__scroll:hover {
  visibility: visible;
}

.navbar {
  display: flex;
  flex-direction: column;
  color: var(--basic-color);
  z-index: 1;
  visibility: visible;
  height: 100%;
}

.topic__block {
  padding: 20px 16px;
}

.topic__list {
  padding: 20px 16px;
  border-top: 1px solid var(--basic-background-light);
}

.topic__list-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  color: var(--basic-color-dark);
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0;
  height: 40px;
  padding-left: 10px;
  border-radius: 4px;
  position: relative;
}

.topic__list-item:hover {
  color: var(--basic-color);
  background: var(--basic-background-light);
}

.topic__list-item:hover .facebook-icon-grey {
  color: var(--basic-color);
}

.topic__list-item:hover .labels-icon {
  color: var(--basic-color);
}

.topic__list-item:hover .settings-icon {
  color: var(--basic-color);
}

.navbar__title a {
  font-family: 'helvetica-now-display-extrabold', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
  color: var(--basic-color);
  text-decoration: none;
}

.navbar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 13px 0;
}

.navbar__title-topics {
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.topic__sort {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--basic-color-dark);
}

.topic__sort>option{
  background-color: var(--basic-background-light);
}

.icon__wrapper {
  display: flex;
  width: 18px;
}

.icon__wrapper svg {
  margin-left: auto;
  margin-right: auto;
}

.icon__wrapper svg path {
  fill: var(--basic-color);
}

.topics__list {
  max-height: 260px;
  overflow-y: auto;
  margin: 10px 0;
  visibility: hidden;
}

.topics__list:hover {
  visibility: visible;
}