.add-label-popup {
  position: relative;
}

.add-label-popup__top-container {
  padding: 24px 24px 16px 24px;
}

.add-label-popup__bottom-container {
  border-top: 1px solid var(--basic-background-light);
  padding: 6px 0 ;
}

.add-label-popup__fullscreen {
  position: absolute;
  top: 24px;
  right: 50px;
  cursor: pointer;
}

.add-label-popup__close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.add-label-popup__fullscreen svg,
.add-label-popup__close svg {
  height: 16px;
  width: 16px;
}

.add-label-popup__fullscreen svg path,
.add-label-popup__close svg path {
  fill: var(--basic-color);
}

.add-label-popup__title {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 16px;
  color: var(--basic-color);
}

.add-label-popup__toggle-wrapper {
  width: 100%;
  height: 40px;
  background: var(--basic-background-light);
  border-radius: 4px;
  display: flex;
}

.add-label-popup__toggle-button {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: #8C8C8C;
  min-height: 40px;
  min-width: 166px;
  border: 1px solid var(--basic-background-light);
  border-radius: 4px;
  background-color: var(--basic-background-light);
  position: relative;
  cursor: pointer;
}

.add-label-popup__toggle-button:hover {
  background: inherit;
  color: var(--basic-color);
}

.add-label-popup__toggle-button--active,
.add-label-popup__toggle-button--active:hover {
  background: var(--basic-background);
  color: var(--basic-color);
}

.add-label-popup__search-container {
  position: relative;
  margin-top: 16px;
}

.add-label-popup__search-container input {
  background-color: var(--basic-background);
  color: var(--basic-color);
}

.add-label-popup__search-container input::placeholder {
  color: var(--basic-color-dark);
}

.add-label-popup__search-container:after {
  content: "";
  position: absolute;
  left: 14px;
  top: 13px;
  bottom: 0;
  width: 20px;
  background: url(magnifier-icon.svg) no-repeat 0 0;
}

.add-label-popup__opinion,
.add-label-popup__theme {
  font-family: 'helvetica-now-text', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  padding: 10px 24px;
  cursor: pointer;
  position: relative;
  color: var(--basic-color);
}

.add-label-popup__opinion:hover,
.add-label-popup__theme:hover {
  background-color: var(--basic-background-light);
}

.add-label-popup__opinion-chooser,
.add-label-popup__theme-chooser {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1002;
  background-color: var(--basic-background);
  width: 160px;
  border-radius: 8px;
  box-shadow:
    0 9px 28px 8px rgba(0, 0, 0, 0.05),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12);
  padding: 6px 0;
}

.add-label-popup__actions {
  width: 160px;
}
