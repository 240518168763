/* Variables */

:root {
    --basic-white: #FFFFFF;
    --basic-black: #000000;
    --basic-red: #FA541C;

    --basic-black-font: #141414;
    --basic-white-link-font: #8C8C8C;
    --basic-black-modalHelperText-font: #595959;

    --basic-white-main-background: #FAFAFA;
    --basic-white-modal-background: #FFFFFF;
    --basic-black-modalWrapper-background: rgba(0, 0, 0, 0.4);

    --basic-white-input-border: #F5F5F5;

    --special-white-button-hover: #EEEEEE;
    --special-red-button-hover: #E74246;
    --special-white-link-hover: #F5F5F5;
    --special-black-navbarItem-hover: #141414;
    --special-white-navbarItem-hover: #F5F5F5;
    --special-red-remove-button: #F5222D;

    --special-facebook-blue: #4267B2;
    --special-twitter-blue: #1DA1F2;
    --special-instagram-magenta: #C13584;
    --special-instagram-background: radial-gradient(
      circle at 30% 107%, #fdf497 0%, #fdf497 5%,
        #fd5949 45%,#d6249f 60%,#285AEB 90%
    );
}

/* Themes */
.dark {
    --scrollbar-thumb: rgba(255, 255, 255, .3);
    --scrollbar-track: rgba(255, 255, 255, .1);
    --basic-background-dark: #262626;
    --basic-background-light: #595959;
    --basic-background: #434343;
    --basic-color: #FFFFFF;
    --basic-color-dark: #D9D9D9;

    --theme-background: #3B5369;
    --theme-border-color: #2D6CA5;
    --opinion-agree-background: #465D3B;
    --opinion-agree-border-color: #4B862D;
    --opinion-disagree-background: #673D3F;
    --opinion-disagree-border-color: #A03338;
}

.light {
    --scrollbar-thumb: rgba(0, 0, 0, .3);
    --scrollbar-track: rgba(0, 0, 0, .1);
    --basic-background-dark: #fafafa;
    --basic-background-light: #F5F5F5;
    --basic-background: #FFFFFF;
    --basic-color: #141414;
    --basic-color-dark: #8C8C8C;

    --theme-background: #F0F5FF;
    --theme-border-color: #D6E4FF;
    --opinion-agree-background: #F6FFED;
    --opinion-agree-border-color: #D9F4BE;
    --opinion-disagree-background: #FFF1F0;
    --opinion-disagree-border-color: #FFCCC7;
}

/* Fonts */

/* helvetica-now-display-extrabold-800 */
@font-face {
    font-family: 'helvetica-now-display-extrabold';
    font-style: extrabold;
    font-weight: 800;
    src: local('Helvetica Now Display Extrabold'), local('Helvetica-Now-Display-Extrabold'),
        url('./fonts/helvetica-now-display-extrabold-800.woff2') format('woff2'),
        url('./fonts/helvetica-now-display-extrabold-800.woff') format('woff'),
}

/* helvetica-now-text-bold-700 */
@font-face {
    font-family: 'helvetica-now-text';
    font-style: bold;
    font-weight: 700;
    src: local('Helvetica Now Text Bold'), local('Helvetica-Now-Text-Bold'),
        url('./fonts/helvetica-now-text-bold-700.woff') format('woff'),
        url('./fonts/helvetica-now-text-bold-700.woff2') format('woff2'),
}

/* helvetica-now-text-bold-500 */
@font-face {
    font-family: 'helvetica-now-text';
    font-style: medium;
    font-weight: 500;
    src: local('Helvetica Now Text Medium'), local('Helvetica-Now-Text-Medium'),
        url('./fonts/helvetica-now-text-medium-500.woff') format('woff'),
        url('./fonts/helvetica-now-text-medium-500.woff2') format('woff2'),
}

/* helvetica-now-text-bold-400 */
@font-face {
    font-family: 'helvetica-now-text';
    font-style: regular;
    font-weight: 400;
    src: local('Helvetica Now Text Regular'), local('Helvetica-Now-Text-Regular'),
        url('./fonts/helvetica-now-text-regular-400.woff') format('woff'),
        url('./fonts/helvetica-now-text-regular-400.woff2') format('woff2'),
}

/* inter-regular-400 */
@font-face {
    font-family: 'inter-regular';
    font-style: regular;
    font-weight: 400;
    src: local('Inter Regular'), local('Inter-Regular'),
        url('./fonts/inter-regular-400.woff') format('woff'),
        url('./fonts/inter-regular-400.woff2') format('woff2'),
}

*{
    margin: 0;
}

body {
    color: var(--basic-black-font);
    background-color: var(--basic-white-main-background);
    height: 100vh;
    overflow: hidden;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

::-webkit-scrollbar  {
  width: 6px;
}

::-webkit-scrollbar-track  {
  background-color: var(--scrollbar-track);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb  {
  background-color: var(--scrollbar-thumb);
  border-radius: 20px;  
}

:focus-visible {
    outline: none;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}