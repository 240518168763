.main__wrapper {
    display: grid;
    grid-template-columns: 288px auto 320px;
    grid-template-rows: auto 10fr;
    grid-template-areas:
        "n h h"
        "n c s";
    background-color: var(--basic-background-dark);
    height: 100vh;
}

.topic__control-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topic__control-panel * {
    color: var(--basic-color);
}

.topic__control-filter {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: 9px 12px 9px 10px;
    width: 82px;
    height: 40px;
    background: var(--basic-background);
    border-radius: 4px;
    font-family: 'helvetica-now-text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}

.filter__icon-wrapper {
    margin-right: 10px;
    display: flex;
    width: 18px;
    height: 20px;
    justify-content: center;
}

.filter__icon-wrapper svg {
    margin-right: auto;
    margin-left: auto;
}

.topic__control-filter:hover {
    background: var(--basic-background-light);
}

.topic__control-filter:focus {
    background: var(--basic-background-light);
}

.topic__control-save-wrapper {
    position: relative;
}

.topic__control-save {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: 9px 12px 9px 10px;
    width: 126px;
    height: 40px;
    background: var(--basic-background);
    border-radius: 4px;
    font-family: 'helvetica-now-text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}

.saved-views-wrapper {
    position: absolute;
    top: 48px;
    left: 0;
    width: 180px;
    min-height: 173px;
    background: var(--basic-background);
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.saved-views-top-container {
    padding-bottom: 4px;
}

.saved-view-container {
    padding: 9px;
    position: relative;
    min-height: 22px;
    cursor: pointer;
}

.saved-view-container:hover {
    background: var(--basic-background-light);
}

.saved-view-container--active {
    background: var(--basic-background-light);
}

.saved-view-container--active .saved-view-name {
    color: var(--basic-red);
}

.saved-view-container--active::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 10px;
    top: 14px;
    right: 10px;
    background: url(red-tick-icon.svg) no-repeat 50% 50%;
}

.saved-view-name {
    font-family: 'helvetica-now-text', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
}

.saved-views-bottom-container {
    padding-top: 4px;
    background: var(--basic-background);
}

.saved-view-control-container {
    padding: 9px;
    position: relative;
    min-height: 22px;
    cursor: pointer;
}

.saved-view-control-container:hover {
    background: var(--basic-background-light);
}

.saved-view-control-name {
    font-family: 'helvetica-now-text', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
}

.save__icon-wrapper {
    margin-right: 10px;
    width: 14px;
    height: 20px;
    justify-content: center;
}

.save__icon-wrapper svg {
    margin-right: auto;
    margin-left: auto;
}

.save__icon-wrapper>svg>path {
    stroke: var(--basic-color);
}

.topic__control-sort-wrapper {
    position: relative;
}


.topic__control-save:hover {
    background: var(--basic-background-light);
}

.topic__control-save:focus {
    background: var(--basic-background-light);
}

.topic__control-sort {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: 9px 12px 9px 10px;
    /*min-width: 170px;*/
    min-width: 238px;
    height: 40px;
    background: var(--basic-background);
    border-radius: 4px;
    font-family: 'helvetica-now-text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}

.sort__icon-wrapper {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    justify-content: center;
    align-items: center;
}

.sort__selected-by {
    font-weight: 500;
}

.sort__icon-wrapper svg {
    margin-left: auto;
    margin-right: auto;
}

.sort__icon-wrapper svg path,
.filter__icon-wrapper svg path {
    fill: var(--basic-color);
}

.topic__control-sort:hover {
    background: var(--basic-background-light);
}

.topic__control-sort:focus {
    background: var(--basic-background-light);
}

.sort-by {
    padding: 8px 0;
    position: absolute;
    top: 48px;
    left: 0;
    width: 180px;
    min-height: 70px;
    background: var(--basic-background);
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.sort-by-container {
    padding: 8px 16px;
    position: relative;
    min-height: 22px;
    cursor: pointer;
}

.sort-by-container:hover {
    background: var(--basic-background-light);
}

.sort-by-container--active {
    background: var(--basic-background-light);
}

.sort-by-text {
    font-family: 'helvetica-now-text', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
}

.sort-by-nested-container {
    position: absolute;
    width: 114px;
    top: -8px;
    right: -118px;
    background: var(--basic-background);
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 6px 16px rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.toggle__container {
    border-radius: 4px;
    background: var(--basic-background-light);
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.topic__view-toggle-button {
    background: var(--basic-background-light);
    border: 1px solid var(--basic-background-light);
    border-radius: 4px;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.topic__view-toggle-button--active {
    background: var(--basic-background);
}

.topic__view-toggle-button:hover:not(.topic__view-toggle-button--active)>.topic__icon-wrapper>svg path,
.topic__view-toggle-button.topic__view-toggle-button--active>.topic__icon-wrapper>svg path {
    fill: var(--basic-color);
}

.topic__icon-wrapper {
    display: flex;
    justify-content: center;
}

.topic__icon-wrapper>svg>path {
    fill: var(--basic-color-dark);
}

.topic__control-filter-modal {
    align-items: flex-start;
}

.topic__control-filter-modal .modal-block {
    margin-top: 5%;
    max-width: 570px;
}

.topic__block-lock {
    opacity: .5;
    pointer-events: none;
}