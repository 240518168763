.header__wrapper {
  grid-area: h;
  background: var(--basic-background);
  padding: 22px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--basic-background-light);
}

.header__title-topic {
  color: var(--basic-color);
  font-family: 'helvetica-now-text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}
